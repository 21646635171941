import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Container } from 'react-bootstrap';

export default function customers() {
  return (
    <Layout>
        <Seo title="Our Customers"/>
        <img className='parallax customers' src="/customers/customers.jpg" alt="Parallax" />
        <div className="banner-cover" />
        <div className="banner animate__animated fadeInDownSmall">
            <h1 className="display-3"><b>Our Customers</b></h1>
        </div>

        <Container fluid className="bg-light pb-4" style={{padding: '5% 0 0', boxShadow: 'inset 0 5px 5px  #bebebe'}}>
            <Container className="mt-0">
                <h6>MEET OUR CUSTOMERS</h6>
                <div className='pt-4'>
                    <img className='customers-img' src="/customers/c41.jpg" alt="PEO C41"/>
                    <img className='customers-img' src="/customers/iws.jpg" alt="PEO IWS"/>
                    <img className='customers-img' src="/customers/space.jpg" alt="PEO Space Systems"/>
                    <img className='customers-img' src="/customers/jpe.jpg" alt="JPEO CBD"/>
                    <img className='customers-img' src="/customers/mts.png" alt="Marine Transportation Systems"/>
                    <img className='customers-img' src="/customers/submarine.jpg" alt="Team Submarine"/>
                    <img className='customers-img' src="/customers/noaa.png" alt="NOAA"/>
                    <img className='customers-img' src="/customers/trans.png" alt="Department of Transportation"/>
                    <img className='customers-img' src="/customers/r3f.png" alt="R3F"/>
                    <img className='customers-img' src="/customers/nswc.png" alt="NSWC"/>
                    <img className='customers-img' src="/customers/erdc.png" alt="ERDC"/>
                    <img className='customers-img' src="/customers/st.png" alt="S&T"/>
                    <img className='customers-img' src="/customers/nawc.png" alt="NAWC"/>
                    <img className='customers-img' src="/customers/nuwc-keyport.png" alt="NUWC Division Keyport"/>
                    <img className='customers-img' src="/customers/air.png" alt="Air Force Research Laboratory"/>
                    <img className='customers-img' src="/customers/nuwc.jpg" alt="NUWC Division Newport"/>
                    <img className='customers-img' src="/customers/usace.png" alt="USACE"/>
                    <img className='customers-img' src="/customers/navsea.png" alt="Naval Sea Systems Command"/>
                    <img className='customers-img' src="/customers/navwar.png" alt="NAVWAR"/>
                    <img className='customers-img' src="/customers/naval.png" alt="Naval Information Warfare Center"/>
                    <img className='customers-img' src="/customers/onr.png" alt="Office of Naval Research"/>
                    <img className='customers-img' src="/customers/peoeis.png" alt="PEO EIS"/>
                    <img className='customers-img' src="/customers/devcom.png" alt="Devcom"/>
                    <img className='customers-img' src="/customers/usgs.png" alt="USGS"/>
                </div>

            </Container>
        </Container>
    </Layout>
  )
}
